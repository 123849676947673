// # packages
import axios, { AxiosError, AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios';
import { boundMethod } from 'autobind-decorator';
// # exceptions
import { ApiRequestException } from '@/exceptions';
// # interfaces
import { iRequestConfig } from '@/interfaces/services/request';
// # constants
import { API_URL } from '@/env';
// # transformers
import { apiResponseTransformer } from '@/transformers';
// # services
import { notifications } from '@/services';

export class ApiRequest {
    /**
     * How long request should wait before timing out.
     *
     * @var {number}
     * @unit milliseconds
     */
    static timeout: number = 30000; // 30 seconds

    /**
     * Makes a DELETE request to the endpoint.
     *
     * @param {string} endpoint The endpoint to query.
     * @param {iRequestConfig} config An optional axios config
     * @param {AxiosInstance} instance An optional instance of axios
     * @returns {Promise<AxiosResponse>}
     * @memberof ApiRequest
     */
    @boundMethod
    public async delete(endpoint: string, config?: iRequestConfig, instance?: AxiosInstance): Promise<AxiosResponse> {
        return (await this.makeAxios(config, instance)).delete(endpoint);
    }

    /**
     * Makes a GET request to the endpoint.
     *
     * @param {string} endpoint The endpoint to query.
     * @param {any} params The data to pass
     * @param {iRequestConfig} config An optional axios config
     * @param {AxiosInstance} instance An optional instance of axios
     * @returns {Promise<AxiosResponse>}
     * @memberof ApiRequest
     */
    @boundMethod
    public async get(endpoint: string, params?: any, config?: iRequestConfig, instance?: AxiosInstance): Promise<AxiosResponse> {
        return (await this.makeAxios(config, instance)).get(endpoint, { params });
    }

    /**
     * Makes a POST request to the endpoint
     *
     * @param {string} endpoint The endpoint to query.
     * @param {any} data The data to pass
     * @param {iRequestConfig} config An optional axios config
     * @param {AxiosInstance} instance An optional instance of axios
     * @returns {Promise<AxiosResponse>}
     * @memberof ApiRequest
     */
    @boundMethod
    public async post(endpoint: string, data: any, config?: iRequestConfig, instance?: AxiosInstance): Promise<AxiosResponse> {
        return (await this.makeAxios(config, instance)).post(endpoint, data);
    }

    /**
     * Makes a PUT request to the endpoint
     *
     * @param {string} endpoint The endpoint to query.
     * @param {any} data The data to pass
     * @param {iRequestConfig} config An optional axios config
     * @param {AxiosInstance} instance An optional instance of axios
     * @returns {Promise<AxiosResponse>}
     * @memberof ApiRequest
     */
    @boundMethod
    public async put(endpoint: string, data: any, config?: iRequestConfig, instance?: AxiosInstance): Promise<AxiosResponse> {
        return (await this.makeAxios(config, instance)).put(endpoint, data);
    }

    /**
     * Handles intercepted errors.
     *
     * @param {AxiosError} error The intercepted error
     * @param {iRequestConfig} config The config for the request that failed
     * @returns {AxiosError|Promise<AxiosResponse>}
     * @memberof ApiRequest
     */
    @boundMethod
    protected handleError(error: AxiosError, config: iRequestConfig = {}): ApiRequestException {
        const apiError = new ApiRequestException(error);

        if (apiError.statusCode === 428) {
            return apiError;
        }

        if (config.displayErrorNotifications !== false) {
            notifications.display({
                theme: 'error',
                title: 'Error',
                message: apiError.extractedMessage,
            });
        }

        return apiError;
    }

    /**
     * Makes an axios instance.
     *
     * @param config Optional config
     * @param instance Optional instance of axios
     * @memberof ApiRequest
     */
    @boundMethod
    protected async makeAxios(config?: iRequestConfig, instance?: AxiosInstance): Promise<AxiosInstance> {
        if (!instance) {
            instance = axios.create(await this.parseConfig(config));
        }

        // intercept responses and apply transformers
        instance.interceptors.response.use(
            (response: AxiosResponse) => Promise.resolve(apiResponseTransformer.fromApiSuccess(response)),
            (error: AxiosError) => Promise.reject(this.handleError(error, config)),
        );

        return Promise.resolve(instance);
    }

    /**
     * Gets the default config and merges it with an optional config param
     *
     * @param {AxiosRequestConfig} config Optional config.
     * @returns {AxiosRequestConfig}
     * @memberof Api
     */
    @boundMethod
    protected async parseConfig(config?: AxiosRequestConfig): Promise<AxiosRequestConfig> {
        const defaultConfig: AxiosRequestConfig = {
            baseURL: API_URL,
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: ApiRequest.timeout,
            ...config,
        };

        return Promise.resolve(defaultConfig);
    }
}

export default new ApiRequest();

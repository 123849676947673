// # interfaces
import { iUserNotification } from '@/interfaces/userNotifications';
// # services
import request from './request';

class UserNotificationsService {
    /**
     * Retrieves notifications from the API
     *
     * @param {number} id
     * @returns {Promise<iUserNotification>}
     */
    public async query(id: number): Promise<iUserNotification> {
        try {
            const response = await request.get(`/user-notifications/${id}`);
            return Promise.resolve(response.data.data);
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     * Saves notifications to the API.
     *
     * @param {iUserNotification} userNotifications
     * @param {number} user_id
     * @returns {Promise<iUserNotification>}
     */
    public async save(userNotifications: iUserNotification, user_id: number): Promise<iUserNotification> {
        try {
            const response = await request.post(`/user-notifications`, {notifications: userNotifications, user_id: user_id});
            return Promise.resolve(response.data.data);
        } catch (err) {
            return Promise.reject(err);
        }
    }
}

export default new UserNotificationsService;

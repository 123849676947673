// # vendor
import * as React from 'react';
import classnames from 'classnames';
// # interfaces
import { iComponentTheme } from '@/interfaces/ui';

interface iProps {
    /**
     * The message.
     */
    children: React.ReactNode | React.ReactNode[];
    /**
     * Message title.
     */
    title?: React.ReactNode;
    /**
     * The visual theme for this component.
     */
    theme?: iComponentTheme | 'info';
}

/**
 * Renders a generic message with theme.
 */
export const Message: React.SFC<iProps> = (props: iProps) => {
    const { children, title, theme } = props;

    const className = classnames('message', {
        [`message--theme-${theme}`]: !!theme,
    });
    const $title = title
        ? (typeof title === 'string' ? <h4 className="message__title">{title}</h4> : title)
        : null;

    return (
        <div className={className}>
            {$title}
            {children}
        </div>
    );
};

Message.defaultProps = {
    theme: null,
};
Message.displayName = 'Message';

import { iApiService } from '@/interfaces/services';
import { iRequestService } from '@/interfaces/services/request';
import { iApiEndpointParams, iPaginatedApiResponse } from '@/interfaces/api';

export default abstract class ApiService<iModel> implements iApiService<iModel> {
    /**
     * The request service.
     *
     * @var {iRequestService}
     */
    protected requestService: iRequestService;

    constructor(requestService: iRequestService) {
        this.requestService = requestService;
    }

    /**
     * Sends a delete request to the API for a single model.
     *
     * @param {number} id
     * @returns {Promise<iModel>}
     */
    public abstract delete(id: number): Promise<iModel>;

    /**
     * Queries the API for a single model.
     *
     * @param {number} id
     * @returns {Promise<iModel>}
     */
    public abstract find(id: number): Promise<iModel>;

    /**
     * Queries the API for a collection of models.
     *
     * @param {iEndpointParams=} params
     * @returns {Promise<iModel[]>}
     */
    public abstract get(params?: iApiEndpointParams): Promise<iPaginatedApiResponse<iModel>>;

    /**
     * Saves a model to the API.
     *
     * @param {iModel} model
     * @returns {Promise<iModel>}
     */
    public abstract save(model: iModel): Promise<iModel>;
}

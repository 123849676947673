import * as moment from 'moment';
import Model from './abstracts/Model';
// # interfaces
import { iForumComment } from '@/interfaces/forum';
import { iModelHasEntities } from '@/interfaces/models';
// # traits
import { mixin } from '@/helpers';
import { HasEntities } from '@/models/traits';

@mixin([HasEntities])
export class ForumComment extends Model<iForumComment> implements iModelHasEntities {
    /**
     * Returns the model's created at date as a moment object.
     */
    public createdAt(): moment.Moment {
        return moment.utc(this._data.created_at);
    }

    /**
     * @ignore Mixin override
     * @returns {string}
     */
    public expandEntities(): string {
        return '';
    }

    /**
     * Returns the model's last edited at date as a moment object.
     */
    public lastEditedAt(): moment.Moment {
        if (!this._data.last_edited_at) {
            return null;
        }

        return moment.utc(this._data.last_edited_at);
    }

    /**
     * Returns the model's updated at date as a moment object.
     */
    public updatedAt(): moment.Moment | null {
        if (!this._data.updated_at) {
            return null;
        }

        return moment.utc(this._data.updated_at);
    }
}

export default ForumComment;

import { boundMethod } from 'autobind-decorator';
import { filter, find } from 'lodash';

export type iTreeItem = Record<string, any> & {
    parent_id?: number;
    children?: iTreeItem[];
};

/**
 * A group of helper methods that allow flat array to be converted to a nested tree structure
 * and back again. Items are expected to have "id" and "parent_id" properties
 *
 * Example item:
 * {
 *   id: 2,
 *   parent_id: 1,
 *   name: "A nested item",
 *   message: "This is a custom property and it's fine to have these ;)"
 * }
 *
 * @todo this was taken from the angular "TreeManager" service, could probably do with some tidying up.
 */
class Tree {
    /**
     * Builds a nested tree structure from a flat array of items
     *
     * @param {iTreeItem[]} items The array of items - {  }
     * @param {object}  itemType  The item type (used for grouping items - see resources)
     * @return {iTreeItem[]}
     */
    @boundMethod
    buildTree(items: iTreeItem[], itemType): iTreeItem[] {
        const itemCategories = filter(items, { type_id: itemType.id });
        return this.treeToArray(
            this.arrayToTree(
                itemCategories,
                {
                    buildLabel: this.buildFilterLabel,
                },
            ),
        );
    }

    /**
     * Turns flat array of items into a nested tree
     *
     * @param {iTreeItem[]} items
     * @returns {Object}
     * @returns {iTreeItem[]} - choose which items to start the tree traversal from (doesn't
     * necessarily need to be the root, but they will be used by default)
     */
    @boundMethod
    arrayToTree(items: iTreeItem[], addChildItemsConfig, rootItems?): iTreeItem[] {
        // start building the tree
        rootItems = rootItems || filter(items, { parent_id: null });
        const tree = rootItems.map(this.addChildItems.bind(null, items, addChildItemsConfig || {}));
        return tree;
    }

    /**
     * Finds and nests child items on a parent item
     *
     * @param {iTreeItem[]} categories
     * @param {Object} customConfig
     * @param {iTreeItem} item
     */
    @boundMethod
    addChildItems(items: iTreeItem[], customConfig, item: iTreeItem): iTreeItem {
        // merge default config with custom
        const config = {
            ...customConfig,
            depth: 0,
            buildLabel: this.buildLabel,
            parentItem: null,
        };

        item.label = config.buildLabel(config.parentItem, item, config.depth);

        // find children
        const children = filter(items, { parent_id: item.id });

        // recursively add child items
        item.children = children.map(this.addChildItems.bind(null, items, {
            depth: config.depth + 1,
            buildLabel: config.buildLabel,
            parentItem: item,
        }));

        return item;
    }

    /**
     * Builds the default label for an item
     * (this function can be overridden by providing custom config)
     *
     * @param {iTreeItem} parentItem
     * @param {iTreeItem} item
     * @return {string}
     */
    @boundMethod
    buildLabel(parentItem: iTreeItem, item: iTreeItem): string {
        if (parentItem) {
            return (parentItem.label || item.name) + '/' + item.name;
        }

        return item.name;
    }

    /**
     * Builds a filter label.
     *
     * @param  {iTreeItem}  parentItem  The parent item
     * @param  {iTreeItem}  item        The item
     * @param  {number}  depth       The depth
     * @return {string}  The select label.
     */
    @boundMethod
    buildFilterLabel(parentItem: iTreeItem, item: iTreeItem, depth: number): string {
        let spacer = '';
        for (let i = 0; i < depth; i++) {
            spacer += '  ';
        }
        return spacer + item.name;
    }

    /**
     * Builds a label showing the path to a nested item.
     *
     * @param {iTreeItem} item
     * @param {iTreeItem[]} items
     * @param {{ itemLabelKey?: string; prefix?: string; }} config
     * @returns {string}
     */
    buildPathLabel(
        item: iTreeItem,
        items: iTreeItem[],
        config: {
            itemLabelKey?: string;
            labelSuffix?: string;
            depth?: number
        } = {},
    ): string {
        const options = {
            itemLabelKey: 'label',
            labelSuffix: '',
            depth: 1,
            ...config,
        };

        // If the element has no parent then we're at the top of the tree and can return the label.
        const parent = find(items, { id: item.parent_id });
        if (!parent) {
            return `${item[options.itemLabelKey]}${options.labelSuffix}`;
        }

        // Recursively go up the tree expanding the label.
        return this.buildPathLabel(parent, items, {
            ...options,
            labelSuffix: ` / ${item[config.itemLabelKey]}${options.labelSuffix}`,
        });
    }

    /**
     * Turns a nested tree structure back into a flat array
     *
     * @param {iTreeItem[]} tree
     * @returns {iTreeItem[]}
     */
    @boundMethod
    treeToArray(tree: iTreeItem[]): iTreeItem[] {
        const items = this.flattenTree(tree);
        return items.map(this.removeChildren);
    }

    /**
     * Traverses a tree structure adding each item to a new array
     *
     * @param  {iTreeItem[]}            tree        The tree
     * @param  {array}             items       The items
     * @param  {number/null}       parentId    The item's parent ID
     * @return {array}
     */
    @boundMethod
    flattenTree(tree: iTreeItem[], items = [], parentId = null): iTreeItem[] {
        for (let i = 0; i < tree.length; i++) {
            // add parent id to this item
            const item = tree[i];
            item.parent_id = parentId;
            // push this item to big ol array we're populating
            items.push(item);
            // do the same for any child items
            item.children = item.children || [];
            items = this.flattenTree(item.children, items, item.id);
        }

        return items;
    }

    /**
     * Removes "children" array from an item
     *
     * @param {iTreeItem} item
     */
    @boundMethod
    removeChildren(item: iTreeItem): iTreeItem {
        delete item.children;
        return item;
    }
}

export default new Tree();

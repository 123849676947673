import Exception from './abstracts/Exception';

/**
 * Thrown when a resource or action cannot be accessed.
 */
class Unauthorised extends Exception {
    public readonly name: string = this.constructor.name;

    constructor(type: 'action' | 'resource' = 'resource', item: string = 'this resource', reason: string = '') {
        super(`You are not authorised to ${type === 'action' ? 'perform the action' : 'access'} "${item}". ${reason}`);
    }
}

export default Unauthorised;

// # abstracts
import Model from './abstracts/Model';
// # interfaces
import { iPoll } from '@/interfaces/polls';

export class Poll extends Model<iPoll> {
    //
}

export default Poll;

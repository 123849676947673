// # abstracts
import Model from '../abstracts/Model';
// # interfaces
import { iForumEntity } from '@/interfaces/forum';
import { iModelHasEntities } from '@/interfaces/models';
// # helpers
import { matchAll } from '@/helpers';
import User from '../User';
import { iUser } from '@/interfaces/users';

export class HasEntities extends Model<{
    body: string,
    entities: iForumEntity[],
}> implements iModelHasEntities {
    /**
     * Combines "body" and "entities" to build an html string.
     *
     * @returns {string}
     */
    public expandEntities(): string {
        let body = this.data.body;

        const matches = [];
        for (const match of matchAll(body, '@\\[(\\d*)\\]')) {
            matches.push(match);
        }

        // We have to replace the entities in reverse order,
        // or the string position of the next match will no longer be valid.
        for (const match of matches.reverse()) {
            // Get the entity for this match.
            const entityIndex: number = parseInt(match[1], 10);
            const entity = this.data.entities[entityIndex];
            if (!entity) { continue; }

            // Replace the matched string with the entity body.
            const entityBody = this.getEntityBody(entity.entity, entity.entity_type, entity.body);
            const chars = body.split('');
            chars.splice(match.index, match[0].length, entityBody);
            body = chars.join('');
        }

        return body;
    }

    /**
     * Gets the body string for a single expanded entity.
     *
     * @param {Record<string, any>} entity
     * @param {iForumEntity['entity_type']} entityType
     * @param {string} placeholder
     * @returns {string}
     */
    protected getEntityBody(
        entity: Record<string, any>,
        entityType: iForumEntity['entity_type'],
        placeholder: string,
    ): string {
        switch (entityType) {
            case 'user':
                return new User(entity as iUser).entityBody(placeholder);
            default:
                return placeholder;
        }
    }
}

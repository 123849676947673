/**
 * An iterable way of calling the exec method of a RegExp.
 * This lets you get all the matches at once.
 *
 * @example
 * const comment = 'Welcome @[0] @[1] and @[2]'
 * const pattern = '(@\\[(\\d*)\\])';
 * for (const match of matchAll(comment, pattern)) {
 *   console.log(match); // [0: "@[0]", 1: "0", index: 8, input: "Welcome @[0] @[1] and @[2]"];
 * }
 *
 * @param {string} input
 * @param {string} pattern
 */
export function* matchAll(input: string, pattern: string): IterableIterator<RegExpMatchArray> {
    // We make a new regex from a pattern string so we can force the g flag,
    // otherwise we can end up in an infinite loop, since the first match will be
    // returned every time.
    const reg = new RegExp(pattern, 'g');
    let match: null | RegExpExecArray;
    while ((match = reg.exec(input)) !== null) {
        yield match;
    }
}

export enum iFileType {
    'audio',
    'document',
    'image',
    'video',
    'unknown',
}

// TODO this would be great as an enum of supported mimetypes
export type iFileMimetype = string;

export interface iFileMimetypeInfo {
    ext: string;
    type: iFileType;
}

export interface iUploadedFile {
    client_filename: string;
    created_at: string;
    ext: string;
    filesize_formatted: string; // eg "5.73KB"
    id: number;
    mimetype: iFileMimetype;
    path: string;
    src: string;
    type: iFileType;
}

export enum iFileDropShapes {
    circle = 'circle',
    rectangle = 'rectangle',
    square = 'square',
}

export interface iFileDropOptions {
    filePattern?: string;
    maxFilesize?: number;
    dragOverClass?: string;
    resize?: boolean;
    resizeSize?: {
        w: number,
        h: number,
    };
    resizeShape?: iFileDropShapes;
    resizeAspectRatio?: number;
}
// # interfaces
import { iPermission } from '@/interfaces/permissions';
import { iPermissionsService } from '@/interfaces/services/permissions';
// # services
import request from './request';

class PermissionsService implements iPermissionsService {
    /**
     * Retrieves permissions from the API
     *
     * @returns {Promise<iPermission[]>}
     */
    public async get(): Promise<iPermission[]> {
        try {
            const response = await request.get('/permissions');
            return Promise.resolve(response.data.data);
        } catch (err) {
            return Promise.reject(err);
        }
    }
}

export default new PermissionsService();

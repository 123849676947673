// # packages
import { AxiosResponse } from 'axios';
// # interfaces
import { iFileType } from '@/interfaces/files';
import { iUploadsEndpointResponse } from '@/interfaces/endpoints/uploads';
// # models
import { FileModel } from '@/models';
// # services
import request from './request';
import { notifications } from '../';
// # transformers
import { formDataTransformer } from '@/transformers';

class UploadsService {
    /**
     * Uploads a file to the API.
     *
     * @param {FileModel} file
     * @returns {Promise<iUploadsEndpointResponse>}
     */
    public async save(file: FileModel): Promise<iUploadsEndpointResponse> {
        try {
            let response: AxiosResponse<any>;
            let endpoint = '/uploads';

            // switch endpoint for images
            if (file.type() === iFileType.image) {
                endpoint += '/img';
            }

            // make a FormData instance containing the file data
            const body: FormData = formDataTransformer.toFormData('POST', {
                path: 'user',
                data: await file.toBase64(),
            });

            // send the request
            response = await request.post(endpoint, body);

            notifications.display({
                theme: 'success',
                title: 'Uploaded',
                message: 'File uploaded',
            });

            return Promise.resolve(response.data);
        } catch (err) {
            return Promise.reject(err);
        }
    }
}

export default new UploadsService();

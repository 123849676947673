// # interfaces
import { iSiteSettings } from '@/interfaces/siteSettings';
// # services
import request from './request';

export interface iSiteSettingsApi {
    all(): Promise<iSiteSettings>;
}

// TODO: extend an "API" service
class SiteSettings {
    /**
     * Retrieves site settings from the API
     *
     * @returns {Promise<iRole[]>}
     */
    public async all(): Promise<iSiteSettings> {
        try {
            const response = await request.get('/settings');
            return Promise.resolve(response.data.data);
        } catch (err) {
            return Promise.reject(err);
        }
    }
}

export default new SiteSettings();

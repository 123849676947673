export const actions = {
    INSTALL_PERMISSIONS: 'INSTALL_PERMISSIONS',
    CLEAR_PERMISSIONS: 'CLEAR_PERMISSIONS',
};

const defaultState = [];

/**
 * Reducer for "permissions" store
 *
 * @param  {iPermissionsState}
 * @param  {iAction}
 * @return {iPermissionsState}
 */
function permissionsReducer(state = defaultState, action) {
    switch (action.type) {
    case actions.INSTALL_PERMISSIONS:
        return action.payload.permissions;
    case actions.CLEAR_PERMISSIONS:
        return defaultState;
    default:
        return state;
    }
}

export default permissionsReducer;

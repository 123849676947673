// # interfaces
import { iValidationError, iValidationErrors } from '@/interfaces/validation';
import { iApiValidationMessages } from '@/interfaces/api';

export class ValidationErrors {
    protected _data: iApiValidationMessages;
    protected _transformed: iValidationErrors;

    constructor(errors: iApiValidationMessages) {
        if (!errors) {
            throw new Error('Attempted to construct ValidationErrors from an empty value');
        }

        this._data = errors;
        this._transformed = this.transformErrors(errors);
    }

    public get errors(): iApiValidationMessages {
        return this._data;
    }

    public get(key: string): null | iValidationError {
        return this._transformed[key];
    }

    public keys(): string[] {
        return Object.keys(this._transformed);
    }

    public map(callback: (messages: iValidationError, key: string) => any) {
        return Object.keys(this._transformed).map((val, key) => callback(this._transformed[val], val));
    }

    protected transformErrors(errors: iApiValidationMessages): iValidationErrors {
        const transformed: iValidationErrors = {};

        Object.keys(errors).forEach(key => {
            const keyParts = new RegExp(/(.*)\.(\d)/).exec(key.toString());
            const isArrayItem = !!keyParts;
            
            if (isArrayItem) {
                // this item is an array key eg "permissions.0"
                // put the messages into "permissions" instead
                const [originalKey, arrayKey, arrayIndex] = keyParts;
                transformed[arrayKey] = transformed[arrayKey] || { messages: [] };
                transformed[arrayKey].messages = transformed[arrayKey].messages.concat(errors[key].map(message => {
                    return `${arrayIndex}: ${message}`;
                }));
            } else {
                transformed[key] = {
                    messages: errors[key],
                };
            }
        });

        return transformed;
    }
}

export default ValidationErrors;

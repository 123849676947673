// # packages
import * as React from 'react';
import * as _ from 'lodash';
import classnames from 'classnames';
// # interfaces
import { iModalProps } from './interfaces';
// # components
import { ModalFrame } from './frame';
import { ClickEventHandler } from '@/components/ClickEventHandler';

export class Modal extends React.Component<iModalProps> {
    static displayName = 'Modal';

    static defaultProps = {
        visible: true,
        isNaked: false,
    };

    public readonly $container: React.Ref<any> = React.createRef();

    /**
     * Renders the content.
     *
     * @returns {React.ReactNode}
     */
    public render(): React.ReactNode {
        const { className, children, onClose, title, isNaked } = this.props;
        const containerClassName = classnames('modal', className);

        return (
            <>
                <div
                    className={containerClassName}
                    ref={this.$container}
                    style={{ display: 'block', overflowY: 'auto' }}
                >
                    <div className="modal-dialog">
                        <div className={isNaked === true ? 'naked-modal' : 'modal-content'}>
                            <ModalFrame
                                onClose={onClose}
                                title={title}
                            >
                                {children}
                            </ModalFrame>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop"></div>

                <ClickEventHandler target={this.$container} />
            </>
        );
    }
}

export default Modal;
export const Frame = ModalFrame;

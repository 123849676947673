import {
    iModel,
    iModelEventHandler,
    iModelEventHandlerMap,
    iModelEventType,
    iModelEventUnsubscriber,
} from '@/interfaces/models';

export default abstract class Model<iData> implements iModel<iData> {
    /**
     * The model data
     *
     * @var {iData}
     */
    protected _data: iData;

    /**
     * The registered event handlers.
     *
     * @var {iModelEventHandlerMap}
     */
    protected _eventHandlers: iModelEventHandlerMap = {
        change: [],
    };

    constructor(model: iData) {
        this._data = model || ({} as any);
    }

    /**
     * Read only access to the modal data.
     *
     * @var {iData}
     */
    public get data(): iData {
        return this._data;
    }

    /**
     * Read only access to the event handlers.
     *
     * @var {iModelEventHandlerMap}
     */
    public get eventHandlers(): iModelEventHandlerMap {
        return this._eventHandlers;
    }

    /**
     * Gets a single data property.
     *
     * @param {keyof iData} key
     * @returns {iData[keyof iData]}
     */
    public get(key: keyof iData): iData[keyof iData] {
        return this.data[key];
    }

    /**
     * Registers a handler function that will be called when an event is fired.
     *
     * @param {iModelEventType} eventType
     * @param {iModelEventHandler} eventHandler
     * @returns {iModelEventUnsubscriber}
     */
    public on(eventType: iModelEventType, handler: iModelEventHandler): iModelEventUnsubscriber {
        const handlers = this._eventHandlers[eventType];
        handlers.push(handler);

        // return a function that removes the handler
        return () => {
            handlers.splice(handlers.indexOf(handler));
        };
    }

    /**
     * Sets a model property
     *
     * @param {keyof iData} key
     * @param {iData[keyof iData]} value
     * @returns {void}
     */
    public set(key: keyof iData, value: iData[keyof iData]): void {
        this._data[key] = value;
        this.fire('change');
    }

    /**
     * Merges data into the model.
     *
     * @param {Partial<iData>} updatedProperties
     * @returns {void}
     */
    public update(updatedProperties: Partial<iData>): void {
        this._data = {
            ...this._data,
            ...updatedProperties,
        };
        this.fire('change');
    }

    /**
     * Calls any registered event handlers registered for an event type.
     *
     * @param {iModelEventType} eventType
     * @returns {void}
     */
    protected fire(eventType: iModelEventType): void {
        this._eventHandlers[eventType].forEach(handler => handler());
    }
}

// # abstracts
import Model from './abstracts/Model';
// # interfaces
import { iForumThread } from '@/interfaces/forum';
import { iModelHasEntities } from '@/interfaces/models';
// # traits
import { mixin } from '@/helpers';
import { HasEntities } from './traits';
// # models
import User from './User';

@mixin([HasEntities])
export class ForumThread extends Model<iForumThread> implements iModelHasEntities {
    /**
     * @ignore Mixin override
     * @returns {string}
     */
    public expandEntities(): string {
        return '';
    }
}

export default ForumThread;

// # interfaces
import { iPermissionKey } from '@/interfaces/permissions';
import { iStore } from '@/interfaces/store';
// # models
import { UserModel } from '@/models';
// # store
import storeInstance from '@/store';

export interface iAuthService {
    /**
     * Determines whether the authenticted user has one or more permissions.
     *
     * @param {...iPermissionKey} permissions
     * @returns {boolean}
     */
    hasPermission(...permissions: iPermissionKey[]): boolean;

    /**
     * Gets the authenticated user.
     *
     * @returns {null | User}
     */
    user: () => null | UserModel;
}

export class AuthService implements iAuthService {
    /**
     * The redux store.
     */
    protected store: iStore;

    /**
     * Cached copy of the current user to save on requests to the store.
     *
     * @var {UserModel}
     */
    protected cachedUser: UserModel;

    constructor(store: iStore) {
        this.store = store;
    }

    /**
     * Determines whether the authenticted user has one or more permissions.
     *
     * @param {...iPermissionKey} permissions
     * @returns {boolean}
     */
    public hasPermission(...permissions: iPermissionKey[]): boolean {
        const user = this.user();
        if (!user) { return false; }
        return user.hasPermission(...permissions);
    }

    /**
     * Gets the authenticted user.
     * TODO: change this to a get method, and cache the current user to avoid
     * making unecessary calls to the store and constructing so many new models.
     *
     * @returns {null | User}
     */
    public user(): null | UserModel {
        if (this.cachedUser) {
            return this.cachedUser;
        }

        const { user } = this.store.getState();
        if (!user) { return null; }

        this.cachedUser = new UserModel(user);

        return this.cachedUser;
    }
}

export default new AuthService(storeInstance);

// # interfaces
import { iSiteSettings } from '@/interfaces/siteSettings';
// # store
import store from '@/store';
// # services
import { siteSettingsApi } from './api';

export interface iSiteSettingsService {
    all(): null | iSiteSettings;
    get(key: keyof iSiteSettings): any;
    load(): Promise<void>;
}

/**
 * Provides access to the site settings.
 */
class SiteSettings implements iSiteSettingsService {
    /**
     * Retrieves the current site settings from the store.
     *
     * @returns {null | iSiteSettings}
     */
    public all(): null | iSiteSettings {
        const { siteSettings } = store.getState();
        return siteSettings;
    }

    /**
     * Retrieves a single site setting from the store by key.
     *
     * @param {string} key
     * @returns {any}
     */
    public get(key: keyof iSiteSettings): any {
        const settings = this.all();
        if (!settings) { return null; }
        return settings[key] || null;
    }

    /**
     * Loads site settings from the API and caches to the store.
     *
     * @returns {Promise<void>}
     */
    public async load(): Promise<void> {
        try {
            const settings = await siteSettingsApi.all();
            store.dispatch({
                type: 'SET_SITE_SETTINGS',
                payload: { settings },
            });
        } catch(err) {
            throw err;
        }
    }
}

export default new SiteSettings();

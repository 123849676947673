/**
 * Class decoratoe that merges in the properties of one or more other classes,
 * similar to how PHP traits work.
 *
 * Note: Interfaces won't filter down to the decorated class,
 * so make sure you implement their interfaces to ensure type safety.
 *
 * @see https://www.typescriptlang.org/docs/handbook/mixins.html
 * @decorator
 *
 * @param {Record<string, any>} baseCtors
 * @returns {(derivedCtor: Function) => void}
 */
export function mixin(baseCtors: Function[]) {
    return function (derivedCtor: Function) {
        baseCtors.forEach(baseCtor => {
            Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
                derivedCtor.prototype[name] = baseCtor.prototype[name];
            });
        });
    };
}

import { iRouteGenerator } from '@/interfaces/routes';
import { ForumThreadModel } from '@/models';

// All available keys we can used to reference a url.
export type iRouteKey =
    | 'admin.plans'
    | 'admin.plans.edit'
    | 'admin.plans.new'
    | 'forum.thread'
    | 'user.profile'
;

// The shape returned by the url function.
type iRouteInfo<iMakeFunc = iRouteGenerator> = {
    path: string;
    make: iMakeFunc;
};

// Interface for the map.
type iRouteGeneratorMap = {
    [key in iRouteKey]: iRouteInfo;
};

// Paths and url generators for all urls defined in iRouteKey.
const urlMap: iRouteGeneratorMap = {
    'admin.plans': {
        path: '/admin/plans',
        make: () => '/admin/plans',
    },
    'admin.plans.edit': {
        path: '/admin/plans/edit/:id',
        make: (id: number) => `/admin/plans/edit/${id}`,
    },
    'admin.plans.new': {
        path: '/admin/plans/new',
        make: () => '/admin/plans/new',
    },
    'forum.thread': {
        path: '/forum/:categorySlug/:slug',
        make: (thread: ForumThreadModel) => {
            const category = thread.data.category;

            if (!category) {
                throw new Error('"category" missing, cannot redirect to thread route.');
            }

            return `/forum/${category.slug}/${thread.data.slug}`;
        }
    },
    'user.profile': {
        path: '/members/:slug',
        make: (slug: string) => `/members/${slug}`,
    },
};

/**
 * Functions that builds URLs we can use to navigate around the site.
 * Hard-coded URLs should be avoided as they're harder to update.
 *
 * Note: Make sure you overload the type interface if you add a route,
 * or typescript won't be aware of it.
 *
 * @example url('admin.plans.edit').path; => "/admin/plans/edit/:id"
 * @example url('admin.plans.edit').make(4); => "/admin/plans/edit/4"
 *
 * @param {iRouteKey} key
 * @returns {iRouteInfo}
 */
function url(key: 'admin.plans.edit'): iRouteInfo<(id: number) => string>;
function url(key: 'admin.plans.new'): iRouteInfo;
function url(key: 'admin.plans'): iRouteInfo;
function url(key: 'forum.thread'): iRouteInfo<(thread: ForumThreadModel) => string>
function url(key: 'user.profile'): iRouteInfo<(slug: string) => string>;
function url(key: iRouteKey): iRouteInfo {
    return urlMap[key];
}

export default url;

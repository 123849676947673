// # abstracts
import Model from './abstracts/Model';
// # interfaces
import { iUser } from '@/interfaces/users';
import { iModelIsEntity } from '@/interfaces/models';
import { iPermissionKey } from '@/interfaces/permissions';
// # helpers
import js_utils from '../../js/utils';
import route from '@/services/route';

export class User extends Model<iUser> implements iModelIsEntity {
    /**
     * Determines whether the user has one or more permissions.
     *
     * @param {...iPermissionKey[]} permissions
     * @returns {boolean}
     */
    public hasPermission(...permissions: iPermissionKey[]): boolean {
        if (!this.data.permissions) { return false; }
        return js_utils.arrayContainsAll(this.data.permissions, permissions);
    }

    /**
     * Gets the body text to be used when this entity is expanded.
     *
     * @param {string} placeholder Placeholder text provided by the entity.
     * @returns {string}
     */
    public entityBody(placeholder: string): string {
        const url = route('user.profile').make(this.data.slug);
        return `<a href="${url}" title="View ${placeholder}'s profile">${placeholder}</a>`;
    }
}

export default User;

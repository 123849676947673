export const actions = {
    INSTALL_ROLES: 'INSTALL_ROLES',
    CLEAR_ROLES: 'CLEAR_ROLES',
};

const defaultState = [];

/**
 * Reducer for "roles" store
 *
 * @param  {iRolesState}
 * @param  {iAction}
 * @return {iRolesState}
 */
function rolesReducer(state = defaultState, action) {
    switch (action.type) {
    case actions.INSTALL_ROLES:
        return action.payload.roles;
    case actions.CLEAR_ROLES:
        return defaultState;
    default:
        return state;
    }
}

export default rolesReducer;

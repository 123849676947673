import { iSubscriptionApiService, iSubscriptionApiSaveParams } from '@/interfaces/services/api/subscription';
import { iSubscription } from '@/interfaces/subscriptions';
import { iRequestService } from '@/interfaces/services/request';
import request from './request';

export class SubscriptionService implements iSubscriptionApiService {
    /**
     * The service used to make API requests.
     */
    protected requestService: iRequestService;

    constructor(requestService: iRequestService) {
        this.requestService = requestService;
    }

    /**
     * Cancels the users' subscription.
     *
     * @returns {Promise<void>}
     */
    public async delete(): Promise<void> {
        try {
            await this.requestService.delete('/subscription', {
                timeout: 10000,
            });
        } catch (err) {
            throw err;
        }
    }

    /**
     * Subscribes the user to a plan.
     *
     * @param {iSubscriptionApiSaveParams} params
     * @returns {Promise<iSubscription>}
     */
    public async save(params: iSubscriptionApiSaveParams): Promise<iSubscription> {
        try {
            const method = params.stripe_id ? this.requestService.post : this.requestService.put;
            const response = await method<iSubscription>('/subscription', params, {
                timeout: 10000,
            });
            return Promise.resolve(response.data);
        } catch (err) {
            throw err;
        }
    }
}

export default new SubscriptionService(request);

import Exception from './abstracts/Exception';

/**
 * An exception to throw when an action is cancelled.
 */
class ActionCancelled extends Exception {
    public readonly name: string = this.constructor.name;
}

export default ActionCancelled;

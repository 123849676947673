import * as React from 'react';
import classnames from 'classnames';

import { iComponentTheme } from '@/interfaces/ui';

export interface iProps {
    /**
     * Tag content.
     *
     * @var {React.ReactNode}
     */
    children: React.ReactNode;

    /**
     * Visual theme applied to the tag.
     *
     * @var {iComponentTheme | 'disabled'}
     */
    theme?: iComponentTheme | 'disabled';
}

/**
 * Renders a simple tag.
 */
export const Tag: React.SFC<iProps> = (props: iProps) => {
    const className = classnames('tag', {
        [`tag--theme-${props.theme}`] : props.theme,
    });

    return (
        <span className={className}>{props.children}</span>
    );
};
Tag.displayName = 'Tag';

// # packages
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { boundMethod } from 'autobind-decorator';

interface iProps {
    /**
     * A DOM node to listen for clicks outside of.
     */
    target: React.Ref<any>;
    /**
     * Called whenever a click is made on the window.
     */
    onClick?: (isOutside: boolean, event: MouseEvent) => any;
    /**
     * Called when a click is detected inside the target
     */
    onClickInside?: (event: MouseEvent) => any;
    /**
     * Called when a click is detected outside of the target
     */
    onClickOutside?: (event: MouseEvent) => any;
    /**
     * Sets a timeout before registering the click handler.
     *
     * @unit milliseconds
     */
    wait?: number;
}

// React.Ref is impossible to work with because it might return void
type ReactRefVoidFix = null | React.RefObject<any>;

/**
 * Allows you to declaratively handle click events around a DOM node.
 */
export class ClickEventHandler extends React.Component<iProps> {
    static defaultProps = {
        wait: 400,
    };

    /**
     * setTimeout instance used to wait before adding an event listener.
     *
     * @var {number}
     */
    protected waitTimeout: number;

    /**
     * Whether an event listener has been added to this.props.target
     */
    protected eventListenerRegistered: boolean = false;

    public componentWillMount(): void {
        this.waitTimeout = window.setTimeout(() => {
            this.addEventListener();
        }, this.props.wait);
    }

    public componentWillUnmount(): void {
        clearTimeout(this.waitTimeout); // make sure the wait timeout isn't going to complete and haunt us
        this.removeEventListener();
    }

    public render() {
        return null;
    }

    /**
     * Adds a click event listener to the window.
     *
     * @returns {void}
     */
    @boundMethod
    protected addEventListener(): void {
        if (this.eventListenerRegistered) { return; }
        window.addEventListener('click', this.handleClick);
        this.eventListenerRegistered = true;
    }

    /**
     * Calls the relevant prop functions when a click event is fired.
     *
     * @param {MouseEvent} event
     * @returns {void}
     */
    @boundMethod
    protected handleClick(event: MouseEvent): void {
        const {
            onClick,
            onClickInside,
            onClickOutside,
            target,
        } = this.props;

        // skip if the target ref is empty or not ready
        if (!target || !(target as ReactRefVoidFix).current) { return; }

        const isOutside = this.isOutside(event);

        if (isOutside === null) { return; }

        // call any provided handlers
        if (onClick) { onClick(isOutside, event); }
        if (onClickInside && !isOutside) { onClickInside(event); }
        if (onClickOutside && isOutside) { onClickOutside(event); }
    }

    /**
     * Removes the event listener attached to the window.
     *
     * @returns {void}
     */
    @boundMethod
    protected removeEventListener(): void {
        if (!this.eventListenerRegistered) { return; }
        window.removeEventListener('click', this.handleClick);
    }

    /**
     * Determines whether a click event occurred inside the target container.
     *
     * @param {MouseEvent} event
     * @returns {null | boolean}
     */
    protected isOutside(event: MouseEvent): null | boolean {
        const { target } = this.props;

        // skip if the target ref is empty or not ready
        if (!target || !(target as ReactRefVoidFix).current) { return; }

        const containerElement = ReactDOM.findDOMNode((target as ReactRefVoidFix).current);

        const eventElement = ReactDOM.findDOMNode(event.target as any);

        // skip if the container or event nodes cannot be found
        if (!containerElement || !eventElement) { return; }

        return !containerElement.contains(eventElement);
    }
}

export default ClickEventHandler;

class Utils {
    /**
     * Determines whether an array contains all of the specified properties.
     *
     * @param {Array<any>} target
     * @param {Array<any>} requiredItems
     * @returns {boolean}
     */
    arrayContainsAll(target, requiredItems) {
        if (!Array.isArray(target)) { throw new Error('First argument must be an array'); }
        if (!Array.isArray(requiredItems)) { throw new Error('Second argument must be an array'); }

        // reducer that returns true if all items are in the array
        const containsAllReducer = (allExist, item) => {
            // skip if a previous item was missing
            if (!allExist) { return false; }
            return target.includes(item);
        };

        return requiredItems.reduce(containsAllReducer, true);
    }
}

export default new Utils();

import Model from './abstracts/Model';
import { iPost, iPostStatus } from '@/interfaces/posts';

export class Post<iModel = iPost> extends Model<iModel> {
    public static STATUS_APPROVED: iPostStatus = 'approved';
    public static STATUS_PENDING: iPostStatus = 'pending';
    public static STATUS_REJECTED: iPostStatus = 'rejected';
}

export default Post;

import * as moment from "moment";
import { iForumApiService } from "@/interfaces/services/api/forum";
import { iRequestService } from "@/interfaces/services/request";
import { iForumHeartbeat } from "@/interfaces/forum";
import requestsService from "./request";

type iApiResponse = {
    data: iForumHeartbeat;
};

export class ForumService implements iForumApiService {
    /**
     * The request service instance.
     */
    protected requestService: iRequestService;

    constructor(requestService: iRequestService) {
        this.requestService = requestService;
    }

    /**
     * @inheritdoc
     */
    public async pulse(since: moment.Moment): Promise<iForumHeartbeat|void> {
        try {
            const response = await this.requestService.get<iApiResponse>(
                '/forum/heartbeat',
                { since: since.unix() },
                { displayErrorNotifications: false },
            );

            // Nothing changed.
            if (response.status === 204) {
                return Promise.resolve();
            }

            return Promise.resolve(response.data.data);
        } catch (err) {
            return Promise.reject(err);
        }
    }
}

export default new ForumService(requestsService);

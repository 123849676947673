// # packages
import * as React from 'react';
import classnames from 'classnames';
// # interfaces
import { iIconConfig, iIconType } from './interfaces';
import { iComponentSize } from '@/interfaces/ui';
// # components
import SpinnerIcon from './components/SpinnerIcon';

export interface iIconProps extends iIconConfig {
    className?: string;
    size?: iComponentSize | 'tiny';
    type: iIconType;
}

/**
 * Renders an icon.
 */
export class Icon extends React.Component<iIconProps> {
    static defaultProps = {
        className: '',
        colorForeground: 'black',
        colorBackground: 'grey',
        animated: false,
        size: 'regular',
    };

    /**
     * Maps icon types to content components
     *
     * @type {Record<iIconType, React.ComponentType<iIconConfig>>}
     */
    static iconMap: Record<iIconType, React.ComponentType<iIconConfig>> = {
        spinner: SpinnerIcon,
    };

    /**
     * Gets the correct child component for this icon
     *
     * @param {iIconProps['type']} type
     * @returns {React.ComponentType<iIconConfig>}
     */
    protected getIconContent(type: iIconProps['type']): React.ComponentType<iIconConfig> {
        return Icon.iconMap[type];
    }

    render() {
        const { animated, className, size, type } = this.props;
        const Content = this.getIconContent(type);
        const containerClassName = classnames(
            'icon',
            `icon-${type}`,
            `icon--size-${size}`,
            {
                'is-animated': animated,
            },
            className,
        );
        return (
            <div className={containerClassName}>
                <Content {...this.props} />
            </div>
        );
    }
}

class Clipboard {
    /**
     * The browser document.
     */
    protected document: Document;

    /**
     * The browser navigator.
     */
    protected navigator: Navigator;

    constructor(document: Document, navigator: Navigator) {
        this.document = document;
        this.navigator = navigator;
    }

    /**
     * Copies a string of text onto the users' clipboard.
     * Achieves this by creating a textarea node and populating with the text.
     *
     * Note: there are more modern/elegant ways to achieve this, but this technique
     * has the best browser support.
     *
     * @param {string} text
     * @returns {void}
     */
    public insert(text: string): void {
        // Create a textarea element and populate it.
        const textarea: HTMLTextAreaElement = this.document.createElement('textarea');
        textarea.value = text;

        // Avoid scrolling to bottom.
        textarea.style.top = '0';
        textarea.style.left = '0';
        textarea.style.position = 'fixed';

        // Add textarea to the DOM.
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();

        // Perform the copy.
        document.execCommand('copy');

        // Remove the textarea.
        textarea.remove();
    }
}

export default new Clipboard(window.document, window.navigator);

// # packages
import * as React from 'react';
// # components
import { Icon, iIconProps } from '@/components/icon';

interface iProps extends Partial<iIconProps> {
    //
}

export class Spinner extends React.Component<iProps> {
    render() {
        return (
            <Icon
                {...this.props}
                type="spinner"
                animated
            />
        );
    }
}

import Exception from './abstracts/Exception';
import { AxiosError } from 'axios';
import { apiResponseTransformer } from '@/transformers';

/**
 * An exception to throw when an API request fails.
 */
class ApiRequest extends Exception {
    /**
     * The axios error that was originally thrown.
     *
     * @var {AxiosError}
     */
    public readonly axiosError: AxiosError;

    /**
     * The name of this error.
     *
     * @var {string}
     */
    public readonly name: string = this.constructor.name;

    constructor(axiosError: AxiosError, message = 'API Request failed') {
        super(message);
        this.axiosError = axiosError;
    }

    /**
     * Human readable message for this exception.
     *
     * @var {string}
     */
    public get extractedMessage(): string {
        const data = this.axiosError.response && this.axiosError.response.data;
        const errorCode = this.axiosError.code;
        const transformed = apiResponseTransformer.fromApiError(data || errorCode);

        return transformed.message;
    }

    /**
     * Returns the status code
     *
     * @var {number}
     */
    public get statusCode(): number {
        const reppy = this.getErrorReponse();
        if (reppy == null) {
            return null;
        }
        return reppy.status;
    }

    /**
     * returns the error message
     */
    public get errorResponseMessage(): string {
        const reppy = this.getErrorReponse();
        if (reppy == null) {
            return null;
        }
        return reppy.data.error.message;
    }

    private getErrorReponse() {
        if (!this.axiosError.response) {
            return null;
        }
        return this.axiosError.response;
    }

    /**
     * Whether or not this request timed out.
     *
     * @var {boolean}
     */
    public isTimedOut(): boolean {
        return this.axiosError.code === 'ECONNABORTED';
    }
}

export default ApiRequest;

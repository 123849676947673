import * as React from 'react';
import { Message } from './';

interface iProps {
    children: React.ReactNode | React.ReactNode[];
}
interface iState {
    error?: any;
}

/**
 * Renders a message if an uncaught error is thrown in a child component
 *
 * @todo add logger to record errors
 */
export class ErrorBoundary extends React.Component<iProps, iState> {
    state: iState = {
        error: null,
    };

    /**
     * Updates local state if an error is thrown from any child component.
     *
     * @param {any} error
     * @returns {iState}
     */
    static getDerivedStateFromError(error): iState {
        return { error };
    }

    render() {
        const { error } = this.state;

        if (error) {
            return (
                <Message theme="danger" title="⚠️ Interface Error">
                    {error ? <p>{(error as any as {}).toString()}</p> : null}
                </Message>
            );
        }

        return this.props.children;
    }
}

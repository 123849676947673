import { iCardsApiService } from '@/interfaces/services/api/cards';
import { iRequestService } from '@/interfaces/services/request';
import { iPaymentCard } from '@/interfaces/payments';
import requestsService from './request';

type iApiResponse = {
    data: {
        stripe_id: null | string;
        card_brand: null | string;
        card_last_four: null | string;
        setup_intent: null | string;
    };
};

export class CardsService implements iCardsApiService {
    /**
     * The request service instance.
     */
    protected requestService: iRequestService;

    constructor(requestService: iRequestService) {
        this.requestService = requestService;
    }

    /**
     * Deletes the users' saved card details.
     *
     * @returns {Promise<void>}
     */
    public async delete(): Promise<void> {
        try {
            await this.requestService.delete('/cards');
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     * Retrieves the users' card from the API.
     *
     * @returns {Promise<null | iPaymentCard>}
     */
    public async get(): Promise<null | iPaymentCard> {
        try {
            const response = await this.requestService.get<iApiResponse>('/cards');
            const card = response.data.data;

            if (!card.card_last_four) {
                return Promise.resolve({
                    brand: card.card_brand,
                    last_four: card.card_last_four,
                    stripe_id: card.stripe_id,
                    setup_intent: card.setup_intent,
                });
            }



            return Promise.resolve({
                brand: card.card_brand,
                last_four: card.card_last_four,
                stripe_id: card.stripe_id,
                setup_intent: card.setup_intent,
            });
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     * Updates the users' saved card details.
     *
     * @param {iPaymentCard} card
     * @returns {Promise<iPaymentCard>}
     */
    public async save(card: iPaymentCard): Promise<iPaymentCard> {
        try {
            const response = await this.requestService.post<iApiResponse>('/cards', {
                card_brand: card.brand,
                card_last_four: card.last_four,
                stripe_id: card.stripe_id,
            });
            const newCard = response.data.data;

            return Promise.resolve({
                brand: newCard.card_brand,
                last_four: newCard.card_last_four,
                stripe_id: newCard.stripe_id,
            });
        } catch (err) {
            return Promise.reject(err);
        }
    }
}

export default new CardsService(requestsService);

import * as React from 'react';
import classnames from 'classnames';

import { iComponentSize, iComponentTheme, iInteractiveComponentProps } from '@/interfaces/ui';
import { Spinner } from '@/components/feedback';

export interface iProps extends iInteractiveComponentProps {
    /**
     * Custom classname.
     */
    className?: string;
    /**
     * The button content.
     */
    children: React.ReactNode;
    /**
     * Applies the "fill" css modifier to make the button full width.
     */
    fill?: boolean;
    /**
     * Puts this component into a loading state.
     */
    loading?: boolean;
    /**
     * Function called when the button is clicked.
     */
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => any;
    /**
     * The button size.
     */
    size?: iComponentSize;
    /**
     * The component theme.
     */
    theme?: iComponentTheme | 'lowlight' | 'primary' | 'secondary';
}

/**
 * Renders a basic clickable button.
 */
export const Button: React.SFC<iProps> = (props: iProps) => {
    const className = classnames(
        'button',
        `button--size-${props.size || Button.defaultProps.size}`,
        `button--theme-${props.theme || Button.defaultProps.theme}`,
        {
            'button--fill': props.fill,
            'button--status-disabled': props.disabled || props.loading,
            'button--status-selected': props.selected,
        },
        props.className,
    );

    const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (props.disabled) { return; }
        props.onClick(event);
    };

    const spinner = props.loading ? (
        <Spinner
            className="button__spinner"
            colourForeground={props.theme === 'lowlight' ? '#000' : '#fff'}
            size={props.size || Button.defaultProps.size}
        />
    ) : null;

    return (
        <button
            className={className}
            disabled={props.disabled || props.loading}
            onClick={clickHandler}
        >
            {spinner}{props.children}
        </button>
    );
};
Button.defaultProps = {
    loading: false,
    onClick: () => null,
    size: 'regular',
    theme: 'primary',
};
Button.displayName = 'Button';

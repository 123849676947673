// # interfaces
import { iSiteSettings } from '@/interfaces/siteSettings';

export const actions = {
    SET_SITE_SETTINGS: 'SET_SITE_SETTINGS',
    CLEAR_SITE_SETTINGS: 'CLEAR_SITE_SETTINGS',
};

type iStoreState = null | iSiteSettings;
const defaultState: iStoreState = null;

/**
 * Reducer for "settings" store
 *
 * @param  {iSiteSettings}
 * @param  {iAction}
 * @return {iSiteSettings}
 */
function siteSettingsReducer(state: iStoreState = defaultState, action) {
    switch (action.type) {
    case actions.SET_SITE_SETTINGS:
        return action.payload.settings;
    case actions.CLEAR_SITE_SETTINGS:
        return defaultState;
    default:
        return state;
    }
}

export default siteSettingsReducer;

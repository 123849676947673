import { find } from 'lodash';
// # interfaces
import { iSubscription } from '@/interfaces/subscriptions';
import { iSubscriptionApiService } from '@/interfaces/services/api/subscription';
import { iSubscriptionService } from '@/interfaces/services/subscription';
import { iAuthService } from './auth';
// # exceptions
import { ActionCancelledException, UnauthorisedException } from '@/exceptions';
// # services
import { auth } from './';
import { subscriptionApi } from './api';

export class SubscriptionService implements iSubscriptionService {
    /**
     * The service used for getting the authenticated user.
     */
    protected authService: iAuthService;

    /**
     * The service used for communicating with the subscription api.
     */
    protected subscriptionApiService: iSubscriptionApiService;

    constructor(
        authService: iAuthService,
        subscriptionApiService: iSubscriptionApiService,
    ) {
        this.authService = authService;
        this.subscriptionApiService = subscriptionApiService;
    }

    /**
     * Cancels the users' current subscription.
     *
     * @returns {Promise<void>}
     */
    public async cancel(): Promise<void> {
        const subscription = await this.get();
        if (!subscription) {
            throw new UnauthorisedException('action', 'cancel subscription', 'You have no active subscription.');
        }

        await this.subscriptionApiService.delete();
    }

    /**
     * Subscribes the user to a plan.
     *
     * @param {number} planId
     * @param {string} stripeToken
     * @returns {Promise<iSubscription>}
     */
    public async create(planId: number, stripeToken: string): Promise<iSubscription> {
        const user = this.authService.user();
        if (!user) {
            throw new UnauthorisedException('action', 'create subscription', 'Not signed in.');
        }

        const subscription = await this.get();
        if (subscription) {
            throw new ActionCancelledException('You already have an active subscription.');
        }

        return await this.subscriptionApiService.save({
            plan_id: planId,
            stripe_id: stripeToken,
        });
    }

    /**
     * Moves the users' subscription to a different plan.
     *
     * @param planId
     */
    public async changePlan(planId: number): Promise<iSubscription> {
        const subscription = await this.get();
        if (!subscription) {
            throw new UnauthorisedException('action', 'change subscription', 'You have no active subscription.');
        }

        return await this.subscriptionApiService.save({ plan_id: planId });
    }

    /**
     * Retrieves the user's subscription.
     *
     * @returns {Promise<null | iSubscription>}
     */
    public async get(): Promise<null | iSubscription> {
        const user = this.authService.user();
        if (!user || !user.data.subscriptions) { return Promise.resolve(null); }

        // Find the first subscription whose end date is null, or in the future.
        // This must be the current active subscription...
        const subscription = find(user.data.subscriptions, sub => {
            if (!sub.ends_at) {
                return true;
            }

            if (new Date(sub.ends_at) > new Date()) {
                return true;
            }

            return false;
        }) || null;

        return Promise.resolve(subscription);
    }
}

export default new SubscriptionService(auth, subscriptionApi);

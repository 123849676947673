import * as React from 'react';
// # interfaces
import { iPromptChildRenderer } from '@/interfaces/ui';
// # components
import { Button, iButtonProps } from '@/components/content';
import { Modal } from '@/components/modal';

export interface iProps<iReturnValue> {
    /**
     * Override the cancel button props.
     */
    cancelButtonProps?: Partial<iButtonProps>;

    /**
     * Function that renders children.
     */
    children: iPromptChildRenderer<iReturnValue>;

    /**
     * Override the continue button props.
     */
    confirmButtonProps?: Partial<iButtonProps>;

    /**
     * Called when the user clicks the cancel button.
     */
    onCancel: () => any;

    /**
     * Called with the final value when the user clicks the confirm button.
     */
    onConfirm: (value: any) => any;

    /**
     * The modal title.
     */
    title?: string;
}

/**
 * React component that gets rendered inside the modal.
 */
export function Prompt<iReturnValue = any>(props: iProps<iReturnValue>): React.ReactElement {
    const [promptValue, setPromptValue] = React.useState();

    /**
     * Closes the modal and rejects the promise.
     *
     * @returns {void}
     */
    const handleCancel = (): void => {
        props.onCancel();
    };

    /**
     * Closes the modal and resolves the promise with the current state value.
     *
     * @returns {void}
     */
    const handleConfirm = (): void => {
        props.onConfirm(promptValue);
    };

    // Gather cancel, continue button props.
    const cancelButtonProps: iButtonProps = {
        children: 'Cancel',
        theme: 'lowlight',
        ...props.cancelButtonProps || {},
    };
    const confirmButtonProps: iButtonProps = {
        children: 'Continue',
        theme: 'primary',
        ...props.confirmButtonProps || {},
    };

    // Render a modal.
  return (
        <Modal
            className="modal--prompt"
            onClose={handleCancel}
            title={props.title}
        >
            {/* Render the children */}
            {
              // @ts-ignore
              props.children({ setPromptValue })
            }

            {/* Render the cancel, continue buttons. */}
            <div className="modal__footer-controls">
                <Button
                    {...cancelButtonProps}
                    onClick={handleCancel}
                />
                <Button
                    {...confirmButtonProps}
                    disabled={!promptValue}
                    onClick={handleConfirm}
                />
            </div>
        </Modal>
    );
}
Prompt.defaultProps = {
    title: 'Confirm',
};

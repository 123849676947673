export const actions = {
    INSTALL_USER: 'INSTALL_USER',
    UPDATE_USER: 'UPDATE_USER',
    CLEAR_USER: 'CLEAR_USER',
};

const defaultState = null;

/**
 * Reducer for "user" store
 *
 * @param  {iUserState}
 * @param  {iAction}
 * @return {iUserState}
 */
function userReducer(state = defaultState, action) {
    switch (action.type) {
    case actions.INSTALL_USER:
        return action.payload.user;
    case actions.UPDATE_USER: {
        return {
            ...state,
            ...action.payload.user,
        };
    }
    case actions.CLEAR_USER:
        return defaultState;
    default:
        return state;
    }
}

export default userReducer;

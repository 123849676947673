import firebase from 'firebase/app'
import 'firebase/messaging'
import request from '../ts/services/api/request'
import {auth} from '../ts/services'
import {Workbox} from 'workbox-window'

const installBtn = document.getElementById('install-button')
const notifyBtn = document.getElementById('notify-button')

let deferredPrompt = null

const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return /iphone|ipad|ipod/.test(userAgent)
}

const isInStandaloneModeIos = () => ('standalone' in window.navigator) && (window.navigator.standalone)

const isInStandaloneModeAndroid = () =>
  (["fullscreen", "standalone", "minimal-ui"].some(
    (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
  )) || (window.navigator.standalone) || document.referrer.includes('android-app://')

if (isIos()) {
  installBtn.style.display = "none"

  if (!isInStandaloneModeIos()) {
    showIosInstall()
  }
}

if (isInStandaloneModeAndroid()) {
  installBtn.style.display = "none"
}

if(notifyBtn) {
  notifyBtn.style.display = "none"
}

if (isUserLoggedIn()) {
  if (typeof Notification !== 'undefined') {
    if (Notification.permission === 'granted') {
      updateToken()
      if(notifyBtn) {
        notifyBtn.style.display = "none"
      }
    } else {
      if(notifyBtn) {
        notifyBtn.style.display = "block"
        notifyBtn.addEventListener("click", async () => {
          await grantNotificationPermissions()
        })
      }
    }
  }
}

function isUserLoggedIn() {
  const login = document.getElementById('taskbarLogin')

  return login === null
}

function showIosInstall() {
  let iosPrompt = document.querySelector(".ios-prompt")
  iosPrompt.style.display = "block"
  iosPrompt.addEventListener("click", () => {
    iosPrompt.style.display = "none"
  })
}

window.addEventListener('beforeinstallprompt', function (e) {
  e.preventDefault()
  deferredPrompt = e
  showAddToHomeScreen()
})

function showAddToHomeScreen() {
  installBtn.style.display = "block"
  installBtn.addEventListener("click", addToHomeScreen)
}

function addToHomeScreen() {
  installBtn.style.display = 'none'  // Show the prompt
  deferredPrompt.prompt()  // Wait for the user to respond to the prompt
  deferredPrompt.userChoice
    .then(function (choiceResult) {

      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt')
      } else {
        console.log('User dismissed the A2HS prompt')
      }

      deferredPrompt = null

    })
}

/*firebase.initializeApp({
  apiKey: process.env.MIX_FIREBASE_API_KEY,
  authDomain: process.env.MIX_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.MIX_FIREBASE_PROJECT_ID,
  storageBucket: process.env.MIX_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.MIX_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.MIX_FIREBASE_APP_ID,
  measurementId: process.env.MIX_FIREBASE_MEASUREMENT_ID,
})*/

async function _initDoTheWork() {
  /*
   * Take care of any async initialization here,
   * and return any result as applicable
   */

  const app = firebase.initializeApp({
    apiKey: 'AIzaSyBfXg67agflDjoaBKZDAkgAxvdBLMFG7qo',
    authDomain: 'ponc-fd227.firebaseapp.com',
    projectId: 'ponc-fd227',
    storageBucket: 'ponc-fd227.appspot.com',
    messagingSenderId: '219404578254',
    appId: '1:219404578254:web:15d30c39398ecdd7698f9f',
    measurementId: 'G-PK0EVQX9WV'
  });
  return { app }; // just as an example
}

function initDoTheWork() {
  if (!initDoTheWork.promise) {
    initDoTheWork.promise = _initDoTheWork();
  }
  return initDoTheWork.promise;
}

initDoTheWork()
  .catch((err) => {
    // you could do nothing here, but I'd log the error somewhere
    console.error("Pre-initialization reported an error", error)
  });



try {
  const wb = new Workbox('/sw.js')

  const showSkipWaitingPrompt = () => {
    // `event.wasWaitingBeforeRegister` will be false if this is
    // the first time the updated service worker is waiting.
    // When `event.wasWaitingBeforeRegister` is true, a previously
    // updated service worker is still waiting.
    // You may want to customize the UI prompt accordingly.

    // Assumes your app has some sort of prompt UI element
    // that a user can either accept or reject.

    const updateBanner = document.querySelector('#pwaUpdateBanner')
    const btnAccept = updateBanner.querySelector('.btn-accept')
    const btnDismiss = updateBanner.querySelector('.btn-dismiss')

    function dismissBanner() {
      updateBanner.style.display = 'none'
    }

    function onAccept() {
      // Assuming the user accepted the update, set up a listener
      // that will reload the page as soon as the previously waiting
      // service worker has taken control.
      wb.addEventListener('controlling', (event) => {
        window.location.reload()
      })

      wb.messageSkipWaiting()
      dismissBanner()
    }

    btnAccept.addEventListener('click', onAccept)
    btnDismiss.addEventListener('click', dismissBanner)
  }

  // Add an event listener to detect when the registered
  // service worker has installed but is waiting to activate.
  wb.addEventListener('waiting', showSkipWaitingPrompt)
  wb.register()
} catch {
  window.onload = async () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/sw.js', {
        scope: '.'
      }).then((registration) => {
        console.log('Registration successful, scope is:', registration.scope)
      }, (error) => {
        console.error(`Service worker registration failed: ${error}`)
      })
    } else {
      console.error('Service workers are not supported.')
    }
  }
}

async function grantNotificationPermissions() {
  Notification.requestPermission().then(async response => {
    if (response === 'granted') {
      let title = 'Notifications enabled!'
      let icon = '/img/sc-logo-cropped.png'
      let body = "You will now receive notifications for your PWA even when you're not looking! 👀"
      new Notification(title, {body, icon})
      await updateToken()
      notifyBtn.style.display = "none"
    }
  }).catch(error => {
    console.log('Failed to obtain notifications permission ', error)
  })
}

export async function updateToken() {
  const { app } = await initDoTheWork();
  const token = await app.messaging().getToken()
  const user = auth.user()

  if (token && user) {
    await request.put(`/users/${user._data.id}/device-tokens`, {
      device_token: token
    })
  }
}

// doTheWork.reinitialize = async function reinitialize() {
//   initDoTheWork.promise = _initDoTheWork();
//   return initDoTheWork.promise;
// }

import * as React from 'react';

interface iProps {
    /**
     * CSS class(es) to apply to the container
     */
    className?: string;
    /**
     * The string that will be rendered as HTML.
     */
    value: string;
}

const HTML: React.SFC<iProps> = (props: iProps) => (
    <div className={props.className} dangerouslySetInnerHTML={{ __html: props.value }} />
);
HTML.defaultProps = {
    className: '',
};
HTML.displayName = 'HTML';

export default HTML;

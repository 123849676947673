// # packages
import * as React from 'react';
// # interfaces
import { iIconConfig } from '../interfaces';

export const SpinnerIcon: React.SFC<iIconConfig> = props => (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 20 20">
        <path fill={props.colourForeground} d="M10,18.667c-4.134,0-7.591-2.893-8.458-6.765C1.405,11.29,0.958,11.521,0.333,10l0,0c0,0.667,0.068,1.318,0.197,1.948c0.901,4.405,4.798,7.718,9.47,7.718c4.688,0,8.597-3.337,9.479-7.766c0.122-0.614,0.187-1.25,0.187-1.9l0,0c-0.229,1.5-1.07,1.283-1.206,1.893C17.598,15.77,14.139,18.667,10,18.667z"/>
    </svg>
);

export default SpinnerIcon;
export type iMethod = 'POST' | 'PUT';

class FormDataTransformer {
    /**
     * Transforms an object into a FormData instance
     *
     * @param {Record<string, any>} model
     * @returns {FormData}
     */
    toFormData(method: iMethod, model: Record<string, any>): FormData {
        const form = new FormData();

        // add model properties to the form
        Object.keys(model).forEach(key => {
            form.append(key, model[key]);
        });

        // add the request method
        form.append('_method', method);

        return form;
    }
}

export default new FormDataTransformer();

// # vendor
import {
    applyMiddleware,
    createStore,
    combineReducers,
    compose,
} from 'redux';
// # interfaces
import { iStore } from '@/interfaces/store';
// # reducers
import reducers from './reducers';

/**
 * Prepares our redux middleware.
 * Enables the redux chrome devtools if available (dev builds only)
 *
 * @param {...reduxMiddleware} customMiddleware
 * @returns {redux middleware}
 */
function makeReduxMiddleware(...customMiddleware) {
    const middleware = [
        applyMiddleware(...customMiddleware),
    ];

    // enable the redux devtools in chrome for dev builds
    const isBrowser = window && Object.keys(window).length; // window is an empty object in test environment
    const isDevBuild = process.env.ENV !== 'production';
    const isChrome = isBrowser && window.navigator.userAgent.indexOf('Chrome') !== -1;
    const hasDevTools = isBrowser && !!window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    const composeEnhancers = isDevBuild && isChrome && hasDevTools ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

    return composeEnhancers(...middleware);
}

/**
 * Creates a redux store
 *
 * @return {iStore}
 */
export function makeStore(): iStore {
    return createStore(
        combineReducers(reducers),
        makeReduxMiddleware(),
    );
}

export default makeStore();

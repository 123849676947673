// # interfaces
import { iFileType } from '@/interfaces/files';
// # abstracts
import Model from './abstracts/Model';

/**
 * A model that represents a binary file.
 *
 * @note this is named "FileModel" so we don't conflict with the typescript "File" type.
 */
export class FileModel extends Model<File> {
    /**
     * Constructs a new FileModel from a Blob.
     *
     * @param {Blob} blob
     * @param {string=} filename
     * @returns {FileModel}
     */
    static fromBlob(blob: Blob, filename?: string): FileModel {
        const file = new File(
            [blob],
            filename || FileModel.makeFilename(),
            {
                type: blob.type,
                lastModified: Date.now(),
            },
        );

        return new FileModel(file);
    }

    /**
     * Makes a unique filename based on the current date/time.
     *
     * @param {string=} append optional string to append to the end
     * @returns {string}
     */
    static makeFilename(append: string = ''): string {
        return `file_${+new Date()}_${append}`;
    }

    /**
     * Maps mimetypes to group types
     *
     * @var {Record<string, iFileType>}
     */
    static mimetypeGroupMap: Record<string, iFileType> = {
        'application/pdf': iFileType.document,
        'application/msword': iFileType.document,
        'application/vnd.openxmlformats-officedocument.wordprocessingmliFileType.document': iFileType.document,
        'application/vnd.ms-excel': iFileType.document,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': iFileType.document,
        'application/vnd.ms-powerpoint': iFileType.document,
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': iFileType.document,
        'application/vnd.oasis.opendocument.text': iFileType.document,
        'text/csv': iFileType.document,
        'text/plain': iFileType.document,
        'audio/mpeg': iFileType.image,
        'audio/ogg': iFileType.image,
        'image/png': iFileType.image,
        'image/jpeg': iFileType.image,
        'image/gif': iFileType.image,
    };

    /**
     * Gets the group type this file belongs to.
     *
     * @returns {iFileType}
     */
    public type(): iFileType {
        return FileModel.mimetypeGroupMap[this.data.type] || iFileType.unknown;
    }

    /**
     * Converts the file to a base64 string.
     *
     * @returns {Promise<string>}
     */
    public toBase64(): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // handle callbacks to resolve/reject promise
            reader.onload = () => resolve(reader.result.toString());
            reader.onerror = reject;

            // read the file
            reader.readAsDataURL(this.data);
        });
    }
}

export default FileModel;

import { iNotification, iNotificationsService } from '@/interfaces/services/notifications';

/**
 * This mimics the angular alerts service.
 */
class Notifications implements iNotificationsService {
    display(notification: iNotification): void {
        // We have to access the angular alerts service via the window global
        // as getting the service directly isn't working.
        const $alerts = (window as any).angularServices.alerts as {
            emit: (notification: Partial<iNotification> & { messages: string[] }) => void;
        };

        $alerts.emit({
            messages: [notification.message],
            title: notification.title,
            theme: notification.theme,
        });
    }
}

export default new Notifications();

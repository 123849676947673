// # interfaces
import { iPlansApiService } from '@/interfaces/services/api/plans';
import { iPaginatedApiResponse, iApiEndpointParams } from '@/interfaces/api';
// # abstracts
import ApiService from './abstracts/ApiService';
// # exceptions
import { NotFoundException } from '@/exceptions';
// # models
import { PlanModel } from '@/models';
// # services
import request from './request';
import { notifications } from '@/services';

export class PlansService extends ApiService<PlanModel> implements iPlansApiService {
    protected endpoint: string = '/plans';

    /**
     * Deletes a single plan model.
     *
     * @param {number} id
     * @returns {Promise<PlanModel>}
     */
    public async delete(id: number): Promise<PlanModel> {
        try {
            const response = await this.requestService.delete(`${this.endpoint}/${id}`);
            const plan = response.data.data;

            notifications.display({
                theme: 'success',
                title: 'Plan deactivated',
                message: '',
            });

            return Promise.resolve(new PlanModel(plan));
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     * Retrieves a single plan from the API
     *
     * @param {number} id
     * @returns {Promise<PlanModel>}
     */
    public async find(id: number): Promise<PlanModel> {
        try {
            const response = await this.requestService.get(`${this.endpoint}/${id}`);
            const plan = response.data.data;

            if (!plan) {
                throw new NotFoundException('Plan not found.');
            }

            return Promise.resolve(new PlanModel(plan));
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     * Retrieves plans from the API
     *
     * @param {Record<string, any>={}} params
     * @returns {Promise<iPaginatedApiResponse<PlanModel>>}
     */
    public async get(params?: iApiEndpointParams<{ active?: number }>): Promise<iPaginatedApiResponse<PlanModel>> {
        try {
            const response = await this.requestService.get(this.endpoint, params);
            const planModels = response.data.data.map(plan => new PlanModel(plan));
            response.data.data = planModels;
            return Promise.resolve(response.data);
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     * Saves a plan to the API
     *
     * @param {PlanModel} plan
     * @returns {Promise<PlanModel>}
     */
    public async save(plan: PlanModel): Promise<PlanModel> {
        try {
            const response = plan.data.id
                ? await this.requestService.put(`${this.endpoint}/${plan.data.id}`, plan.data)
                : await this.requestService.post(this.endpoint, plan.data);

            notifications.display({
                message: 'Plan has been updated.',
                theme: 'success',
                title: 'Saved',
            });

            return Promise.resolve(new PlanModel(response.data.data));
        } catch (err) {
            return Promise.reject(err);
        }
    }
}

export default new PlansService(request);

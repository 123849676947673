import Model from './abstracts/Model';
import { iPlan } from '@/interfaces/plans';

export default class Plan extends Model<iPlan> {
    /**
     * Whether this plan is active
     * (ie. has not been deactivated.)
     *
     * @returns {boolean}
     */
    isActive(): boolean {
        return !this.data.deactivated_at;
    }
}

// # abstracts
import Model from './abstracts/Model';
// # interfaces
import { iSurvey } from '@/interfaces/surveys';

export class Survey extends Model<iSurvey> {
    //
}

export default Survey;

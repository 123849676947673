abstract class Exception<iData = Record<string, any>> extends Error {
    __proto__: Error;

    /**
     * The name of this error type.
     * This is shown in the error output when logged.
     *
     * @var {string}
     */
    public abstract readonly name: string;

    constructor(message: string) {
        super(message);

        /**
         * Some magical wizardry I don't understand to fix an issue in typescript.
         * Checking instanceof on a class that extends Error doesn't work correctly.
         *
         * @see https://github.com/Microsoft/TypeScript/issues/13965
         */
        this.__proto__ = new.target.prototype;
    }

    /**
     * A place to put stuff.
     *
     * @var {iData}
     */
    public data: iData;
}

export default Exception;

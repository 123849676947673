// # abstracts
import Model from './abstracts/Model';
// # interfaces
import { iForumCategory } from '@/interfaces/forum';

export class ForumCategory extends Model<iForumCategory> {
    //
}

export default ForumCategory;

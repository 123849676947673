import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { uniqueId } from 'lodash';
// # interfaces
import { iPromptChildRenderer } from '@/interfaces/ui';
// # components
import { iButtonProps } from '@/components/content';
import { Prompt } from '@/components/feedback';
// # exceptions
import { ActionCancelledException } from '@/exceptions';

/**
 * Options for the prompt method.
 */
export interface iPromptOptions {
    cancelButtonProps?: Partial<iButtonProps>;
    continueButtonProps?: Partial<iButtonProps>;
    title?: string;
}

class UI {
    /**
     * Renders a component inside a modal, with cancel and continue buttons.
     * This is intended for handling user interaction from an async method.
     *
     * @example
     * try {
     *      const options = { title: 'Are you sure you want to do that? };
     *      const chosenOption = await ui.prompt(props => <ChooseOption {...props} />, options);
     *      // Prompt was confirmed by the user, and we got the option that was chosen.
     * } catch (err) {
     *      // Prompt was cancelled by the user.
     * }
     *
     * @param {iPromptChildRenderer} renderChildren
     * @param {iPromptOptions} options
     * @returns {Promise<any>}
     */
    public prompt<iReturnValue = any>(
        renderChildren: iPromptChildRenderer<iReturnValue>,
        options: iPromptOptions = {},
    ): Promise<iReturnValue> {
        return new Promise((resolve, reject) => {
            // Make sure there's a div on the page we can render the modal into.
            const containerId = uniqueId('react-prompt-container');
            let $container = document.getElementById(containerId);

            if (!$container) {
                const $el = document.createElement('div');
                $el.setAttribute('id', containerId);
                document.getElementsByTagName('body')[0].append($el);
                $container = $el;
            }

            /**
             * Closes the modal and rejects the promise.
             *
             * @returns {void}
             */
            const handleCancel = (): void => {
                ReactDOM.unmountComponentAtNode($container);
                reject(new ActionCancelledException('Modal prompt UI was cancelled by the user.'));
            };

            /**
             * Closes the modal and resolves the promise with the chosen value.
             *
             * @returns {void}
             */
            const handleConfirm = (value): void => {
                ReactDOM.unmountComponentAtNode($container);
                resolve(value);
            };

            const component = (
                <Prompt
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                    {...options}
                >
                    {renderChildren}
                </Prompt>
            );

            ReactDOM.render(component, $container);
        });
    }
}

export default new UI();

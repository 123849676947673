// # packages
import axios, { AxiosResponse } from 'axios';
// # interfaces
import { iRole } from '@/interfaces/roles';
// # services
import request from './request';
import { notifications } from '../';

// TODO: extend an "API" service
class RolesService {
    /**
     * Retrieves roles from the API
     *
     * @param {Record<string, any>={}} params
     * @returns {Promise<iRole[]>}
     */
    public async query(params: Record<string, any> = {}): Promise<iRole[]> {
        try {
            const response = await request.get(`/roles`, params);
            return Promise.resolve(response.data.data);
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     * Saves a role to the API.
     * Will make a POST request if the role has no "id" property,
     * otherwise a PUT request will be made.
     *
     * @param {iRole} role
     * @returns {Promise<iRole>}
     */
    public async save(role: iRole): Promise<iRole> {
        try {
            let response: AxiosResponse<any>;

            if (role.id) {
                response = await request.put(`/roles/${role.id}`, role);
            } else {
                response = await request.post(`/roles`, role);
            }

            notifications.display({
                theme: 'success',
                title: 'Saved',
                message: `${role.id ? 'Updated' : 'Added'} role: ${role.label}`,
            });

            return Promise.resolve(response.data.data);
        } catch (err) {
            return Promise.reject(err);
        }
    }

    public async delete(role: iRole): Promise<void> {
        try {
            if (!role.id) {
                throw 'Cannot delete a role that does not have an "id" property';
            }

            await request.delete(`/roles/${role.id}`);

            notifications.display({
                theme: 'success',
                title: 'Deleted',
                message: `Role: ${role.label}`,
            });

            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    }
}

export default new RolesService;

// # packages
import * as React from 'react';
// # interfaces
import { iModalProps } from './interfaces';

interface iProps extends Pick<iModalProps, 'children' | 'title' | 'onClose'> {}

export class ModalFrame extends React.Component<iProps> {
    static displayName = 'ModalFrame';

    /**
     * Renders the content.
     */
    public render(): React.ReactNode {
        const { children, onClose, title } = this.props;

        return (
            <div className="modal__frame">
                <header className="modal__header">
                    <h2 className="modal__title">{title}</h2>
                    <button className="btn-c2a style-link modal__close-button" onClick={onClose}>Close</button>
                </header>
                {children}
            </div>
        );
    }
}

export default ModalFrame;

import permissions from './permissions';
import roles from './roles';
import siteSettings from './siteSettings';
import user from './user';

// map our store structure to the reducers that manage them
export default {
    permissions,
    roles,
    siteSettings,
    user,
};

// # interfaces
import { iApiError, iApiValidationMessages } from '@/interfaces/api';
import { iRequestError } from '@/interfaces/errors';
// # models
import { ValidationErrors } from '@/models';

class ApiRequestTransformer {
    public readonly defaultErrorMessage = `Request couldn't be completed.`;

    constructor() {
        this.fromApiError = this.fromApiError.bind(this);
        this.isDebugResponse = this.isDebugResponse.bind(this);
        this.fromDebugResponse = this.fromDebugResponse.bind(this);
    }

    /**
     * Transforms a successful API response.
     *
     * @param {iData=any} data
     * @returns {iData}
     */
    public fromApiSuccess<iData = any>(data: any): iData {
        return data;
    }

    /**
     * Transforms an unsuccessful API response.
     *
     * @param {iApiError} error
     * @returns {iRequestError}
     */
    public fromApiError(error: iApiError): iRequestError {
        const data = this.fromDebugResponse(error);
        const isDebugResponse = this.isDebugResponse(error);

        // determine data type
        const isArray = Array.isArray(error);
        const isObject = typeof data === 'object';
        const isString = typeof data === 'string';

        // potential messages
        const messageFromString = isString && data;
        const messageFromArray = isArray && data[0];
        const messageFromObject = isObject && (data as any).message;


        return {
            message: messageFromString || messageFromArray || messageFromObject || this.defaultErrorMessage,
            fields: !isDebugResponse && !isArray && isObject ? new ValidationErrors(data as iApiValidationMessages) : null,
        };
    }

    /**
     * Determines whether an API error response is a Laravel debug message.
     *
     * @param {iApiError} error
     * @returns {boolean}
     */
    protected isDebugResponse(error: iApiError): boolean {
        const data = error as any;

        const isDebugResponse =
            data.code !== undefined
            && data.exception !== undefined
            && data.file !== undefined
            && data.trace !== undefined;

        return isDebugResponse;
    }

    /**
     * Transforms a Laravel debug response into the data structure we would expect in production.
     *
     * @param {iApiError} error
     * @returns {iApiError}
     */
    protected fromDebugResponse(error: iApiError): iApiError {
        return this.isDebugResponse(error) ? {
            message: (error as any).exception,
        } : error;
    }
}

export default new ApiRequestTransformer;

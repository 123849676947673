import Exception from './abstracts/Exception';

/**
 * Thrown when a class is missing a required dependency.
 */
class MissingDependency extends Exception {
    public readonly name: string = this.constructor.name;

    constructor(dependencyName: string, caller: string, message?: string) {
        super(message || `Required dependency "${dependencyName}" missing in "${caller}"`);
    }
}

export default MissingDependency;
